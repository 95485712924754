// 导入router所需的方法
import { createRouter, createWebHashHistory } from 'vue-router'
// 路由参数配置
const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path: '/',
            name: 'index',
            title: '登录',
            component: () => import('@/views/home/index.vue')
        }
    ]
})
export default router
